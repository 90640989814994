<script setup lang="ts">
import type { HomeBanners } from "@/types";

type DepositStreakBannerType = HomeBanners[number];

defineProps<{ banner: DepositStreakBannerType }>();

const { t } = useT();
const { open } = useTaoModals();

const { depositInfoData } = useTaoDepositStreak();

const btnText = computed(() => (depositInfoData.value?.canWheel ? t("Get my prize") : t("Make a purchase")));

const handleClick = () => {
	if (depositInfoData.value?.canWheel) {
		open("LazyOModalDepositStreak");
		return;
	}

	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};
</script>

<template>
	<MBanner
		class="deposit-streak-banner"
		:bg-img="banner.backgroundImage"
		:bg-img2x="banner.backgroundImage2x"
		:image="banner.image"
		:image-mobile="banner.imageMobile"
		:bg-color="banner.background"
		:second-image="banner.secondImage"
		:second-image-mobile="banner.secondImageMobile"
		:type="banner.type"
	>
		<template #default>
			<AText type="h7" class="banner-title" as="div">
				<i18n-t keypath="Purchase {key1} times {key2} for a special prize!">
					<template #key1>
						{{ depositInfoData?.maxNumberOfDeposit }}
					</template>
					<template #key2>
						<br />
					</template>
				</i18n-t>
			</AText>
		</template>
		<template #description>
			<div class="app-banner__info">
				<AText :size="{ full: 36, md: 28 }" :modifiers="['bold', 'uppercase']">
					<i18n-t keypath="Get up to {key}% back">
						<template #key>
							<br />
							{{ depositInfoData?.maxPercentage }}
						</template>
					</i18n-t>
				</AText>
			</div>
		</template>
		<template #actions>
			<AButton variant="primary" size="lg" class="app-banner__btn" @click="handleClick">
				<AText :modifiers="['uppercase', 'bold']">{{ btnText }}</AText>
			</AButton>
		</template>
	</MBanner>
</template>

<style lang="scss" scoped>
.deposit-streak-banner {
	&:deep(.app-banner__desc) {
		text-align: center;

		@include media-breakpoint-up(md) {
			text-align: left;
		}
	}
}

.banner-title {
	@include media-breakpoint-up(md) {
		br {
			display: none;
		}
	}
}
</style>
